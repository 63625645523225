<template>
  <vx-card no-shadow>

    <vs-input class="w-full mb-base" type="password" label-placeholder="Old Password (*)" v-model="old_password" />
    <vs-input class="w-full mb-base" type="password" label-placeholder="New Password (*)" v-model="new_password" />
    <vs-input class="w-full mb-base" type="password" label-placeholder="Confirm Password (*)" v-model="new_password_confirmation" />

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click.prevent="storeData">Save Changes</vs-button>
    </div>
  </vx-card>
</template>

<script>
import axios from '@/axios'
export default {
  data () {
    return {
      old_password: '',
      new_password: '',
      new_password_confirmation: ''
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    storeData () {
      const formData = new FormData();
      formData.set('old_password', this.old_password)
      formData.set('new_password', this.new_password)
      formData.set('new_password_confirmation', this.new_password_confirmation)

      axios.post('auth/change-password', formData)
           .then(({data: res}) => {
            this.$vs.notify({
              title: "Successful",
              text: res.msg_status,
              iconPack: "feather",
              icon: "icon-check-circle",
              color: "success"
            });

            this.$router.push('/')
           })
           .catch(err => {
             this.$vs.notify({
              title: "Failed",
              text: err.data.msg_status,
              iconPack: "feather",
              icon: "icon-check-circle",
              color: "danger"
            });
           }) 
    }
  }
}
</script>
