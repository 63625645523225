<template>
  <vx-card no-shadow v-show="!loading">
    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar :src="url" size="70px" class="mr-4 mb-4" />
      <div>
        <input
          type="file"
          @change="addImage"
          class="hidden"
          ref="update_logo_input"
          accept="image/*"
        />
        <vs-button
          class="mr-4 sm:mb-0 mb-2"
          @click.prevent="$refs.update_logo_input.click()"
          >Upload photo</vs-button
        >
        <vs-button type="border" color="danger" @click.prevent="removeImage"
          >Remove</vs-button
        >
        <p class="text-sm mt-2" v-show="!errors.first('avatar')">Allowed JPG, SVG or PNG</p>
        <span class="block text-sm mt-2 text-danger">{{ errors.first('avatar') }}</span>
      </div>
    </div>

    <!-- Info -->
    <vs-input
      class="w-full"
      label="Name (*)"
      v-model="payload.name"
    ></vs-input>
    <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
    <vs-input
      type="number"
      class="w-full mt-3"
      label="Phone Number (*)"
      v-model="payload.no_phone"
    ></vs-input>
    <span class="block text-sm mt-2 text-danger">{{ errors.first('no_phone') }}</span>
    <vs-input
      class="w-full mt-3"
      label="Email (*)"
      v-model="payload.email"
    ></vs-input>
    <span class="block text-sm mt-2 text-danger">{{ errors.first('email') }}</span>
    <div class="input-group">
      <span class="block ml-1 mt-3 mb-1 text-sm">Address</span>
      <vs-textarea class="w-full mb-3" v-model="payload.address"></vs-textarea>
    </div>
    <!-- <vs-alert icon-pack="feather" icon="icon-info" class="h-full my-4" color="warning">
      <span>Your account is not verified. <a href="#" class="hover:underline">Resend Confirmation</a></span>
    </vs-alert> -->

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click.prevent="storeData"
        >Save Changes</vs-button
      >
      <vs-button
        class="ml-4 mt-2"
        type="border"
        color="warning"
        @click.prevent="getData"
        >Reset</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      payload: {
        no_phone: "",
        name: "",
        email: "",
        address: "",
        avatar: ""
      },

      url: null,
      loading: true
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.avatar = file;
    },

    removeImage() {
      this.url = null;
      this.payload.avatar = "";
    },

    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      axios
        .get("/profile")
        .then(({ data: res }) => {
          this.payload.name = res.data.user.name;
          this.payload.no_phone = res.data.user.phoneNumber;
          this.payload.email = res.data.user.email;

          if (res.data.profile.avatar != null) {
            this.url = res.data.profile.avatar;
          }
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(res => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    storeData() {
      const payload = {
        name: this.payload.name,
        phoneNumber: this.payload.no_phone,
      }

      this.$vs.loading({
        type: 'sound'
      })

      axios
        .put(`/profile`, payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          window.location.reload()
        })
        .catch(err => {
          this.$setErrorsFromResponse(err.data.errors);
          setTimeout(() => {
            this.$validator.errors.clear();
          }, 2000);
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  },

  created() {
    this.getData();
  }
};
</script>
